//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Paging from '../../components/paging.vue';
import { getDateformat } from '../../util/getDate';
import dataNone from '@/components/no';
export default {
  components: {
    Paging,
    dataNone,
  },
  data() {
    return {
      // 总数据量
      total: 0,
      freightList: [],
      // 当前页
      page: 1,
      // 每页多少条数据，
      pageNum: 10,
    };
  },
  created() {
    let utils = this.$store.state.utils;
    if (utils.is_record) {
      this.page = utils.page;
      this.pageNum = utils.rows;
      utils.is_record = !1;
    }
    this.getFreightList();
  },
  methods: {
    getFreightList() {
      this.$axios
        .post(this.$api.set.freightList, {
          page: this.page,
          rows: this.pageNum,
        })
        .then(res => {
          if (res.code == 0) {
            let list = res.result.list;
            for (let i in list) {
              list[i].areas_infos = list[i].areas_infos || [];
              list[i].default_freight = JSON.parse(list[i].default_freight);
              list[i].update_time = getDateformat(list[i].update_time);
              list[i].is_select = list[i].is_select ? !0 : !1;
              list[i].areas_infos.unshift({
                additional_amount: list[i].default_freight.additional_amount,
                additional_fee: list[i].default_freight.additional_fee,
                first_amount: list[i].default_freight.first_amount,
                first_fee: list[i].default_freight.first_fee,
                area_detail: [{ name: '全国统一配送' }],
              });
              for (let y in list[i].areas_infos) {
                let name = '';
                list[i].areas_infos[y].area_detail.map(item => (name += item.name + ';'));
                list[i].areas_infos[y].name = name;
                list[i].areas_infos[y].is_open = !0;
              }
            }
            console.log(list);
            this.total = res.result.total;
            this.freightList = list;
          } else {
            this.$message({
              message: res.msg,
              type: 'warning',
            });
          }
        });
    },
    edit(i) {
      localStorage.setItem('freightInfo', JSON.stringify(this.freightList[i]));
      this.$router.push({
        path:'/set/freightEdit',
        query:{
          id:this.freightList[i].id,
          page:this.page,
          rows:this.pageNum
        }
      })
    },
    //删除运费
    freightDel(id, i) {
      this.$confirm('删除后，原使用此运费模板的商品，将使用默认模板', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.set.freightDel, {
            id: id,
          })
          .then(res => {
            if (res.code == 0) {
              this.freightList.splice(i, 1);
              this.$message({
                message: '删除成功',
                type: 'success',
              });
            } else {
              this.$message({
                message: res.msg,
                type: 'warning',
              });
            }
          });
      });
    },
    selectChange(row, index) {
      let is_select = row.is_select;
      this.$axios.post(this.$api.set.setDefaultFreight, { id: row.id, is_select: row.is_select ? 1 : 0 }).then(res => {
        if (res.code == 0) {
          if (res.code == 0) {
            this.freightList.map(item => (item.is_select = !1));
            this.freightList[index].is_select = is_select ? !0 : !1;
          } else {
            this.$message({
              message: res.msg,
              type: 'warning',
            });
          }
        } else {
          this.$message({
            message: res.msg,
            type: 'warning',
          });
        }
      });
    },
    // 分页更新数据
    updateData(val, status) {
      if (status == 0) {
        this.pageNum = val;
        this.getFreightList();
      } else {
        this.page = val;
        this.getFreightList();
      }
    },
    addFreight() {
      this.$router.push({
        path: '/set/freight',
      });
    },
  },
};
